import React from 'react';
import styled from 'styled-components'

const Tag = ({ children }) => {

  return (
    <StyledContainer>
      {children}
    </StyledContainer>
  );
}

const StyledContainer = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 3px 5px;
  border-radius: 5px;
  background-color: ${props => props.theme.colors.brand};
  color: ${props => props.theme.colors.white};
  font-weight: 800;
  font-size: 10px;
  line-height: 14px;
  margin: 0 7px 7px 0;
  text-transform: uppercase;
`

export default Tag;
