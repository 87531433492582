import React from 'react'
import styled from 'styled-components'

const Text = ({ heroDescription, children }) => {
  if (heroDescription) return (
    <StyledP>{children}</StyledP>
  )
}

const StyledP = styled.p`
  font-weight: 700;
  font-size: 18px;
`

export default Text
