import React from 'react';
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'


import Card from 'components/global/Card'

const StyledCardsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 120px;    
  flex-wrap: wrap;
  & > div {
    margin-bottom: 60px;
  }
`

const ActusList = () => {

  const data = useStaticQuery(graphql`
    query {
      allContentfulArticle(
        filter: {type: {eq: "Actu"}}
        sort: { fields: [date], order: DESC },
        ) {
        nodes {
          id
          title
          slug
          date(formatString: "Do MMMM YYYY", locale: "fr")
          tags
          heroImage {
            gatsbyImageData(
              width: 660
              height: 450
            )
          }
          accroche {
            accroche
          }
          type
        }
      }
    }
  `)

  const actus = data.allContentfulArticle.nodes
  const actusRender = actus.map(actu => {
    return (
      <Card key={actu.id} post={actu} />
    )
  })

  return (
    <StyledCardsContainer>
      {actusRender}
    </StyledCardsContainer>
  )
}

export default ActusList
