import React from "react";
import styled from "styled-components";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import breakpoint from "styled-components-breakpoint";
import { useStaticQuery, graphql } from "gatsby";

import Heading from "components/global/Heading";
import Text from "components/global/Text";

const PageHero = ({ pageTitle, pageDecription, imgName }) => {
  const data = useStaticQuery(graphql`
    query {
      allFile(filter: { relativeDirectory: { eq: "hero" } }) {
        nodes {
          name
          childImageSharp {
            gatsbyImageData(height: 400, placeholder: BLURRED)
          }
        }
      }
      contentfulImageProposDeNous {
        image {
          gatsbyImageData(height: 400, placeholder: BLURRED)
        }
      }
    }
  `);

  const localImgs = data.allFile.nodes;
  const aboutImg = data.contentfulImageProposDeNous.image;
  let image = null;

  if (imgName === "about") {
    image = getImage(aboutImg);
  } else {
    const findImg = localImgs.find((img) => img.name === imgName);
    image = getImage(findImg);
  }

  return (
    <StyledContainer>
      <div>
        <Heading>{pageTitle}</Heading>
        <Text heroDescription>{pageDecription}</Text>
      </div>
      <GatsbyImage image={image} alt={pageTitle} />
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 30px;
  ${breakpoint("lg")`
    margin-top: 70px;
    flex-direction: row;
  `}
  & > * {
    flex: 0 0 50%;
    &:first-child {
      margin-bottom: 80px;
      ${breakpoint("lg")`
      margin-bottom: 0;
      padding-right: 80px;
    `}
    }
    img {
      border-radius: 20px;
    }
  }
`;

export default PageHero;
