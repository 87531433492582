import React from 'react'

import Seo from 'components/global/Seo'
import Layout from '../components/global/Layout'
import Container from 'components/global/Container'
import PageHero from 'components/global/PageHero'
import ActusList from 'components/pages/actus/ActusList'

const Actus = () => {

  return (
    <>
      <Seo pageTitle="Nos actus" />
      <Layout>
        <Container>
          <PageHero
            pageTitle="Nos actus"
            pageDecription={["Toute l’actualité de LM-Ingénierie.", <br key="0" />, "Notre vision et expertise."]}
            imgName="actus"
          />
          <ActusList />
        </Container>
      </Layout>
    </>
  )
}


export default Actus


