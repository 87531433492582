import React from 'react';
import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint';
import { Link } from 'gatsby'
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Heading from 'components/global/Heading'
import Tag from 'components/global/Tag'
import SecondaryBtn from 'components/global/Button/SecondaryBtn';

const Card = ({ post, isDate }) => {

  const { title, date, heroImage, type, tags, accroche, slug } = post

  const image = getImage(heroImage)

  const tagsRender = tags.map(tag => {
    return <Tag key={tag}>{tag}</Tag>
  })

  return (
    <StyledContainer>
      <Link to={"/" + slug}>
        <StyledImageContainer>
          <GatsbyImage image={image} alt={title} />
          <StyledOverlay />
          <StyledTextOnImage>
            <StyledHeading two>{title}</StyledHeading>
            {isDate ? (
              <StyledDate>{date}</StyledDate>
            ) : null}
          </StyledTextOnImage>
        </StyledImageContainer>
      </Link>
      <StyledTextContainer>
        <StyledTagsContainer>
          {tagsRender}
        </StyledTagsContainer>
        <p>{accroche.accroche}</p>
        <Link to={"/" + slug}>
          <SecondaryBtn>
            {type === "Projet" ? "Voir le projet" : type === "Actu" ? "Voir l'actu" : null}
          </SecondaryBtn>
        </Link>
      </StyledTextContainer>
    </StyledContainer>
  );
}

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 330px;
  ${breakpoint('sm')`
    margin: 0 15px;
  `}
`
const StyledTextOnImage = styled.div`
  position: absolute;
  bottom: 0;
  padding:  0 0 15px 20px;
  display: flex;
  flex-direction: column;
  & > * {
    color: ${props => props.theme.colors.white};
  }
`
const StyledHeading = styled(Heading)`
  margin: 0;
`
const StyledDate = styled.p`
  font-style: italic;
  font-size: 12px;
`
const StyledOverlay = styled.div`
  position: absolute;
  top: 0;
  background-color: red;
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, #364350 0%, rgba(54, 67, 80, 0) 70%);
  border-radius: 20px;
`
const StyledTagsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 8px;
`
const StyledImageContainer = styled.div`
  position: relative;
  img {
    border-radius: 20px;
    width: 100%;
  }
`
const StyledTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;
  button {
    margin-top: 15px;
  }
`

Card.defaultProps = {
  isDate: true
}

export default Card;
